<template>
  <v-card-text>
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="editedItem.name"
          label="Название"></v-text-field>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: 'FormSettingsCommon',
  props: {
    editedItem: Object
  }
}
</script>

<style scoped>

</style>
